<template>
  <div class="publicMessage">
    <!-- <div class="title">公告信息</div> -->
    <div class="publicMessageInfo">
      <div class="pubilck-info">{{ info }}</div>
      <div class="express-message">
        <div class="info">
          <ul
            :class="{ move: animate }"
            :style="{'margin-top':height,}"
            @click="allInfo()"
            class="public-moudle"
            ref="publicMoudle"
          >
            <!-- :class="{ move: animate }"-->
            <li
              :key="index"
              class="express-list"
              v-for="(item, index) in expressData"
            >{{ item.info }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <van-overlay z-index="999" :show="show" @click="show = false">
      <div  class="wrapper">
        <div class="block">
          <ul>
            <li v-for="(item,index) in expressDatas" :key="index">
              {{item.info}}
            </li>
          </ul>
        </div>
      </div>
    </van-overlay>-->
  </div>
</template>

<script>
import utils from "../../../assets/js/utils";

export default {
  name: '',
  data () {
    return {
      msg: '',
      info: null,
      show: false,
      publicTime: null,
      expressData: [
        // {
        //   id: 0,
        //   info: "阿斯顿发发发大事1",
        // }, {
        //   id: 1,
        //   info: "阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事2",
        // },
        // {
        //   id: 2,
        //   info: "阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事3",
        // },
        // {
        //   id: 3,
        //   info: "阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事4",
        // },
        // {
        //   id: 4,
        //   info: "阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事阿斯顿发发发大事5",
        // }
      ],
      height: '',
      animate: false,
      expressDatas: []
    }
  },
  created () {
  },
  mounted () {
    // setInterval(this.beginMove, 2000);

    // 公告信息
    this.$request.get(this.$api.newPublicMessage).then(res => {
      // console.log("ddd", res);
      this.info = res.data.info;
      this.publicTime = res.data.dateTime;
    });

    // 发货信息
    this.$request.get(this.$api.publicMessage).then(res => {
      this.expressData = res.data;
      this.expressDatas = JSON.parse(JSON.stringify(this.expressData));
      if (this.expressData !== "" && this.expressData.length > 0) {
        setInterval(this.beginMove, 2000);

      }

    });


  },
  computed: {
  },
  filters: {
    formatData (time) {
      return utils.format(time, "yyyy-MM-dd");
    }
  },
  methods: {
    beginMove () {
      this.animate = true;
      //  console.log('this.$refs.publicMoudle.children',this.$refs);
      if (this.$refs.publicMoudle !== undefined) {

        // console.log('this.$refs.publicMoudle.children',this.$refs);
        
        let height = this.$refs.publicMoudle.children[0].offsetHeight;
        this.height = -(height + 10) + 'px'
        console.log('555555555555', this.$refs)
        setTimeout(() => {
          this.expressData.push(this.expressData[0]);
          this.expressData.shift();
          this.height = 0 + 'px'
          this.animate = false;
        }, 1000);
      } else {
        return
      }

    },
    allInfo () {
      // this.show = true;
      this.$router.push({
        path: '/express',
        query: this.expressDatas
      })
    }
  }
}
</script>

<style lang="less" scoped>
.publicMessage {
  margin-top: 15px !important;
  .title {
    font-size: 18px;
  }
  .publicMessageInfo {
    margin-top: 10px;
    // background-color: rgba(92, 99, 110, 0.2);
    // padding: 5px 5px;
    // border-radius: 3px;
    .pubilck-info {
      background-color: rgba(92, 99, 110, 0.2);
      padding: 15px 15px;
      border-radius: 3px;
    }
    .express-message {
      margin-top: 4px;
      background-color: rgba(92, 99, 110, 0.2);
      padding: 5px 5px;
      border-radius: 3px;
    }
  }
}
.notice-swipe {
  height: 35px;
  line-height: 1;
}

.van-notice-bar {
  height: 35px;
  background-color: transparent;
  padding: 0;
  color: #000 !important;
  border-radius: 3px !important;
}
.ship {
  margin-top: 15px;
}
.shipMessage {
  .title {
    font-size: 18px;
  }
}

.express-message {
  // margin-top: 30px;

  .info {
    border-top: 1px solid transparent;
    height: 105px;
    overflow: hidden;
    // position: relative;
    ul {
      // position: absolute;
      // transition: all 1s;
      // top: 0;
      li {
        font-size: 14px;
      }
    }
  }
  ul {
  }
  .express-list {
    margin-top: 10px;
    // height: 110px;
  }
}

.move {
  // margin-top: -120px;
  transition: all 1s;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  // height: 60%;
  background-color: #fff;

  // overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
}
</style>

