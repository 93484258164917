<template>
  <div class="top">
    <div class="swiper-container">

      
      <!-- 车间直播视屏 -->
    

      <!-- <van-swipe
          :autoplay="900000000"
          @change="onChange"
          class="my-swipe"
          indicator-color="white"
        >
          <van-swipe-item :key="index" class="my-swipe-box" v-for="(item,index) in videoData">
            <div class="title">{{item.roomName}}</div>
            <van-icon @click="fullScreen(item)" class="icon-open" name="enlarge" />
            <div id="player-con1">
              <video
                :id="'video'+item.id"
                @click="playVideo(`video${item.id}`)"
                allowfullscreen="true"
                autoplay="autoplay"
                class="video"
                muted
                playsinline
                preload="auto"
                style="object-fit:fill"
                x-webkit-airplay="allow"
                x5-video-player-fullscreen="true"
              ></video>
            </div>
          </van-swipe-item>
      </van-swipe>-->
      <!-- 
                @click="playVideo(`video${item.id}`)"
      -->
      <video
        @click="playVideo()"
        allowfullscreen="true"
        autoplay="autoplay"
        class="video"
        controls
        id="video"
        
        playsinline
        preload="auto"
        style="object-fit:fill"
        x-webkit-airplay="allow"
        x5-video-player-fullscreen="true"
      ></video>
      <div class="check_video">
        <div class="live_video" ref="live">
          <div @click="live" class="title" v-if="btnStatus">直播</div>
          <div
            :class="[currentIndexs == index?'active':'']"
            :key="index"
            @click="exchange(item,index)"
            class="video_name"
            v-else
            v-for="(item,index) in videoData"
          >{{item.roomName}}</div>
        </div>
        <div class="has_video" ref="has">
          <div @click="has" class="title" v-if="!btnStatus">录播</div>
          <div
            :class="[indexs == index?'active':'']"
            :key="index"
            @click="exchange(item,index)"
            class="video_name"
            v-else
            v-for="(item,index) in videos"
          >{{item.name}}</div>
        </div>
      </div>
    </div>

    <!-- 人物介绍开始 -->
    <!-- 1,人物头像列表 -->

    <div class="arrow-box">
      <div class="outside" ref="outside" v-show="liStatus">
        <div class="user">
          <ul class="long" v-show="liStatus">
            <li
              :key="index"
              @click="lookInfo(item,index)"
              :class="[headerIndex == index && show?'header_active':'']"
              class="user-header"
              ref="user"
              v-for="(item,index) in parterInfo"
            >
              <img :src="`${pubilckPath}/${item.headAddress}`" alt />
            </li>
          </ul>
        </div>
      </div>
      <div class="arrow" v-show="liStatus && parterInfo.length > 6">
        <van-icon name="arrow" />
      </div>
    </div>

    <!-- 人物介绍 -->
    <div class="intro" v-if="show">
      <div class="img">
        <img :src="pubilckPath+ '/' + userInfo.url" alt="">
      </div>
      <div class="text">
        <div class="info">
          <span>{{ userInfo.name }}</span>
					<span>{{ userInfo.jobs }}</span>
					<span v-if="tokenStatus">{{ userInfo.phone }}</span>
					<span v-else>{{ userInfo.phone | hiddenPhone }}</span>
        </div>
        <div>
          {{str}}
        </div>
      </div>
    </div>

    <!-- 2,遮罩层人物简介开始 -->
    <!-- <div class="box">
      <van-swipe
        :autoplay="900000"
        :show-indicators="false"
        @change="userChange"
        class="my-swipe user-swipe"
        ref="swipe"
      >
        <van-swipe-item :key="index" v-for="(item,index) in parterInfo">
          <div class="img">
            <img :src="`${pubilckPath}/${item.headAddress}`" alt />
          </div>
          <div class="user-info">
            <div>{{item.name}}</div>
            <div>{{item.jobs}}</div>
            <div>{{item.phone}}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>-->
  </div>
</template>

<script>



import Hls from '../../../assets/js/hls'
import screenfull from "screenfull";
import $ from 'jquery'
import { mapState } from 'vuex'

export default {
  name: '',
  data () {
    return {
      videoData: null,
      loading: true,
      parterInfo: [],
      timeArr: [],
      timer: null,
      liStatus: true,
      videos: [],
      btnStatus: false,
      currentIndex: 0,
      currentIndexs:0,
      indexs: null,
      player: '',
      introArr:[],
      str:'',
      userInfo:[],
      timer:'',
      show: false,
      headerIndex:null, 

    }
  },
  created () {
  },
  mounted () {
    // this.videoPlay('https://monitor.xiubi.com.cn/live/A00167/livestream.m3u8')
    //     var player = new Aliplayer({https://monitor.xiubi.com.cn/live/A00167/livestream.m3u8
    //   id : "player-con",
    //   source: "http://103.91.10.191/live/A00167/livestream.m3u8",
    //   width: "100%",
    //   height: "200px",
    //   autoplay: true,
    //   isLive: true,
    //   rePlay: false,
    //   playsinline: true,
    //   preload: true,
    //   controlBarVisibility: "hover",
    //   useH5Prism: true,
    //   skinLayout: [
    //     {
    //       name: "controlBar",
    //       align: "blabs",
    //       x: 0,
    //       y: 0,
    //       children: [
    //         {
    //           name: "liveDisplay",
    //           align: "tlabs",
    //           x: 15,
    //           y: 6
    //         },
    //         {
    //           name: "fullScreenButton",
    //           align: "tr",
    //           x: 10,
    //           y: 10
    //         },
    //         {
    //           name: "setting",
    //           align: "tr",
    //           x: 15,
    //           y: 12
    //         },
    //         {
    //           name: "volume",
    //           align: "tr",
    //           x: 5,
    //           y: 10
    //         }
    //       ]
    //     }
    //   ]
    // }, function (player) {
    //     console.log("The player is created");
    //   }
    // );
    this.getParterInfo();
    this.getPlay();
    // this.scroll()
    // var _this = this;
    // window.addEventListener('scroll', function () {
    //   let top = document.documentElement.scrollTop
    //   if (top > 50) {
    //     _this.liStatus = false;
    //   } else {
    //     _this.liStatus = true;
    //   }
    // })

  },
  updated () {
    // this.getVideo(this.videoData[0].roomNo, `video${this.videoData[0].id}`)
  },
  computed: {
    ...mapState(['swiperShow','tokenStatus'])
  },
  watch: {
  },
  filters: {
		hiddenPhone(phone) {
			// console.log('phone',phone);
			return phone.substr(0, 3) + '******' + phone.substr(9);
		}
	},
  methods: {
    // videoPlay (src) {
    //   if (this.player != "") {
    //     this.player.dispose()
    //   }
    //   this.player = new Aliplayer({
    //     id: "player-con",
    //     source: src,
    //     width: "100%",
    //     height: "240px",
    //     autoplay: true,
    //     isLive: true,
    //     rePlay: false,
    //     playsinline: true,
    //     preload: true,
    //     controlBarVisibility: "hover",
    //     useH5Prism: true
    //   }, function (player) {
    //     console.log("The player is created", player);
    //   }
    //   );
    // },
    fullScreen (item) {
      let element = document.getElementById(`video${item.id}`)
      this.Screen(element)
    },
    // scroll () {
    //   var _this = this;
    //   window.addEventListener('scroll', function () {
    //     let top = document.documentElement.scrollTop
    //     let dom = document.querySelector('.top')
    //     let body = document.querySelector('.scroll-area')

    //     if (top > 46) {
    //       dom.classList.add('sticky')
    //       let topHeight = dom.offsetHeight;
    //       body.style.paddingTop = topHeight + 'px'
    //       // debugger
    //       _this.$store.state.swiperShow = false;
    //     } else {

    //       dom.classList.remove('sticky')
    //       body.style.paddingTop = 0 + 'px'
    //       _this.$store.state.swiperShow = true;
    //     }
    //   })
    // },
    Screen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {

        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitSupportsFullscreen) {
        //解决iPhone 全屏 
        element.webkitEnterFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    },
    // 获取员工的信息（头像，职位，电话）
    getParterInfo () {
      this.$request.get(this.$api.parterInfo).then(res => {
        console.log('res000', res);
        this.parterInfo = res.data.content;
      })
    },
    step() {
			clearInterval(this.timer)
			this.str = '';
			let text = '';
			let index=0;
			if(this.introArr.length<=0){
				return
			}
			this.introArr.forEach((item,index)=>{
				text += item.info;
			})
			console.log('str',this.str);
			this.timer = setInterval(()=>{
				
				this.str = text.slice(0,index++)
				
				if(index>text.length){
					clearInterval(this.timer)
					
				}
			},50)
		},
    lookInfo(item,index){
console.log('【', item, index);
			if(item.details!="" && item.details !=null){
				this.introArr = JSON.parse(item.details);
				this.step(index);
			}else{
				this.introArr = [];
				this.str = '';
				clearInterval(this.timer)
			}
			console.log('【', this.introArr);
			if (this.headerIndex == index) {
				this.show = !this.show;
        this.headerIndex = null;
			} else {
				this.headerIndex = index;
				this.show = true;
			}

			this.userInfo.url = item.headAddress;
			this.userInfo.jobs = item.jobs;
			this.userInfo.name = item.name;
			this.userInfo.phone = item.phone;
    },
    // lookInfo (index) {
    //   this.setTimeClose()
    //   // 切换到当前人物的index
    //   this.$refs.swipe.swipeTo(index)
    //   // 出现动画
    //   let dom = document.querySelector('.box');
    //   dom.classList.remove('disappear');
    //   dom.classList.add('appear')
    //   // 为当前人物头像添加点击后class
    //   console.log('ref', this.$refs.user[index].classList.contains('scale'));
    //   this.$refs.user.forEach((item, i) => {
    //     if (i == index) {
    //       if (this.$refs.user[index].classList.contains('scale')) {
    //         dom.classList.remove('appear')
    //         dom.classList.add('disappear')
    //         this.$refs.user[i].classList.remove('scale');
    //       } else {
    //         this.$refs.user[index].classList.add('scale');
    //       }
    //     } else {
    //       this.$refs.user[i].classList.remove('scale');
    //     }
    //   })
    //   this.timeArr.push(new Date().getTime())
    // },
    userChange (index) {
      this.setTimeClose()
      this.$refs.user.forEach((item, i) => {
        if (i == index) {
          this.$refs.user[index].classList.add('scale');
        } else {
          this.$refs.user[i].classList.remove('scale');
        }
      })
    },
    setTimeClose () {
      if (this.timer) {
        // debugger
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        let box = document.querySelector('.box')

        if (box.classList.contains('appear')) {
          box.classList.remove('appear')
          box.classList.add('disappear')
          this.$refs.user.forEach(item => {
            item.classList.remove('scale');
          })
        }
      }, 20000)
    },
    //只让当前index的video播放
    onChange (index) {
      if (this.videoData.length > 0) {
        this.videoData.forEach((item, i) => {
          if (i == index) {
            // debugger
            let currentDom = document.getElementById(`video${item.id}`)

            currentDom.play()
          } else {
            let dom = document.getElementById(`video${item.id}`)
            dom.pause()
          }
        })
      } else {
        return

      }
    },
    // 切换主画面视频
    exchange (item, index) {
      if (item.roomNo) {
        // window.location.reload();
        this.getVideo(item.roomNo)
        this.currentIndexs = index
        this.indexs = null
      } else if (item.videoAddress) {
        var video = document.getElementById('video')
        this.indexs = index
        this.currentIndexs = null
        video.src = this.pubilckPath + "/" + item.videoAddress
      } else {
        return
      }
    },
    getPlay () {

      // 直播
      this.$request.get(this.$api.videoUrl, { sort: 'sort,asc' }).then(res => {
        this.videoData = res.data.content
        console.log('rrrrrrrrrr', this.videoData);

        this.getVideo(this.videoData[0].roomNo)
        // this.$nextTick(() => {
        //   let dom = document.querySelectorAll('.video')
        //   console.log('rrrrrrr', dom[0].id, dom);
        //   dom.forEach((item, index) => {
        //     this.getVideo(this.videoData[index].roomNo, item.id)

        //   })

        // })


      })
      // 录播
      this.$request.get(this.$api.getVideos, { sort: 'sort,asc' }).then(res => {
        this.videos = res.data.content;
        console.log('rrrr2', this.videos);

        if (this.videos.length > 0) {
          this.videos.forEach((item, index) => {
            this.videoData.push(item)
          })
        }

        console.log('rrrr', this.videoData);

        // this.videoData
        this.$nextTick(() => {

          
          // 视频自动播放
          let videoDom = document.querySelector('video')
          videoDom.play();




          // let dom = document.querySelectorAll('.video')
          // console.log('rrrrrrr', dom[0].id, dom);
          // dom.forEach((item, index) => {
          //   if (this.videoData[index].roomNo) {
          //     this.getVideo(this.videoData[index].roomNo, item.id)
          //   } else {
          //     item.src = this.pubilckPath + '/' + this.videoData[index].videoAddress
          //   }
          // })

        })

      })
    },

    // 点击直播
    live () {
      this.$refs.live.style.width = "80%"
      this.$refs.has.style.width = "20%"
      this.btnStatus = false;
    },
    // 点击录播
    has () {


      // this.videoPlay('https://monitor.xiubi.com.cn/live/A00168/livestream.m3u8')
      // this.$forceUpdate()
      this.$refs.live.style.width = "20%"
      this.$refs.has.style.width = "80%"
      this.btnStatus = true;
    },
    // getPlay () {

    //   this.$request.get(this.$api.videoUrl, { sort: 'sort,asc' }).then(res => {
    //     this.videoData = res.data.content
    //     console.log('rrrrrrrrrr', this.videoData);

    //     this.$request.get(this.$api.getVideos, { sort: 'sort,asc' }).then(res => {
    //       this.videos = res.data.content;
    //       console.log('rrrr2', this.videos);

    //       if (this.videos.length > 0) {
    //         this.videos.forEach((item, index) => {
    //           this.videoData.push(item)
    //         })
    //       }

    //       console.log('rrrr', this.videoData);

    //       // this.videoData
    //       this.$nextTick(() => {
    //         let dom = document.querySelectorAll('.video')
    //         console.log('rrrrrrr', dom[0].id, dom);
    //         dom.forEach((item, index) => {
    //           if (this.videoData[index].roomNo) {
    //             this.getVideo(this.videoData[index].roomNo, item.id)
    //           } else {
    //             item.src = this.pubilckPath + '/' + this.videoData[index].videoAddress
    //           }
    //         })

    //       })

    //     })
    //     this.$nextTick(() => {
    //       let dom = document.querySelectorAll('.video')
    //       console.log('rrrrrrr', dom[0].id, dom);
    //       dom.forEach((item, index) => {
    //         this.getVideo(this.videoData[index].roomNo, item.id)

    //       })

    //     })


    //   })
    // },
    playVideo () {
      let videoDom = document.getElementById('video')
      videoDom.play();

    },
    // playVideo (dom) {
    //   console.log('doms', dom);
    //   let videoDom = document.getElementById(dom)
    //   console.log('doms', videoDom);
    //   videoDom.muted = true;
    //   videoDom.play();
    //   // 处理个人介绍关闭
    //   let box = document.querySelector('.box')
    //   if (box.classList.contains('appear')) {
    //     box.classList.remove('appear')
    //     box.classList.add('disappear')
    //   }
    //   this.$refs.user.forEach((item, index) => {
    //     console.log('ref', item);
    //     item.classList.remove('scale')
    //   })
    // },
    getVideo (roomNo) {
      let that = this;
      var pathName = roomNo;
      var video = document.getElementById('video');
      // var box = document.querySelector('.box')
      // var van = document.querySelector('.van-nav-bar')
      if (Hls.isSupported()) {
        var hls = new Hls({
          debug: true,
        });
        // hls.loadSource('https://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8');
        hls.loadSource('https://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8');
        // http://103.91.210.191/live/A00167/livestream.m3u8
        video.play();


        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          // video.muted = false;
          video.play();
          // box.style.height = a + 20 + "px"
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = 'http://103.91.210.191/live/' + pathName + '/livestream.m3u8';
        video.addEventListener('canplay', function () {
          // video.muted = false;
          video.play();
        });
      }
    },
    // playVideo (dom) {
    //   console.log('doms', dom);
    //   let videoDom = document.getElementById(dom)
    //   console.log('doms', videoDom);
    //   videoDom.muted = true;
    //   videoDom.play();
    //   // 处理个人介绍关闭
    //   let box = document.querySelector('.box')
    //   if (box.classList.contains('appear')) {
    //     box.classList.remove('appear')
    //     box.classList.add('disappear')
    //   }
    //   this.$refs.user.forEach((item, index) => {
    //     console.log('ref', item);
    //     item.classList.remove('scale')
    //   })
    // },
    // getVideo (roomNo, videoId) {
    //   let that = this;
    //   var pathName = roomNo;
    //   var video = document.getElementById(videoId);
    //   var box = document.querySelector('.box')
    //   var van = document.querySelector('.van-nav-bar')
    //   if (Hls.isSupported()) {
    //     var hls = new Hls({
    //       debug: true,
    //     });
    //     // hls.loadSource('https://monitor.xiubi.com.cn/live/' + pathName + '/livestream.m3u8');
    //     hls.loadSource('http://103.91.210.191/live/' + pathName + '/livestream.m3u8');
    //     http://103.91.210.191/live/A00167/livestream.m3u8

    //     hls.attachMedia(video);
    //     hls.on(Hls.Events.MEDIA_ATTACHED, function () {
    //       video.muted = false;
    //       video.play();
    //       let a = video.offsetHeight;
    //       let b = document.querySelector('.van-swipe-item')
    //       let swiperDom = document.querySelector('.swiper-container')
    //       swiperDom.style.height = a + 20 + 'px';
    //       b.style.height = a + 20 + 'px'
    //       // box.style.height = a + 20 + "px"
    //     });
    //   } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //     video.src = 'http://103.91.210.191/live/' + pathName + '/livestream.m3u8';
    //     video.addEventListener('canplay', function () {
    //       video.play();
    //       let a = video.offsetHeight;
    //       console.log('aaaaaa', a);
    //       let swiperDom = document.querySelector('.swiper-container')
    //       swiperDom.style.height = a + 20 + 'px';
    //       // box.style.height = a + 20 + "px"
    //     });
    //   }
    // },
  }
}
</script>

<style scoped lang="less">
.top {
  // position:fixed;
  width: 100%;
  background: #fff;
}
.padding-top {
}
.sticky {
  position: fixed;
  top: 0;
  padding-bottom: 20px;
  z-index: -1;
}
.van-swipe__track {
  width: 100%;
}
/deep/.van-sticky {
  position: relative;
}
.my-swipe .van-swipe-item {
  height: 210px;
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.my-swipe-box {
  position: relative;
  width: 100%;
  .title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: absolute;
    z-index: 999;
    font-size: 14px;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 150px;
  text-align: center;
  background-color: transparent;
}
video {
  width: 100%;
}
#player-con1 {
  height: 210px;
}

.icon-open {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}

.user {
  height: 60px;
  width: 340px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 25px;
  // display: flex;
  // justify-content: left;
  // flex-wrap: wrap;
  // margin-top: 20px;

  // overflow: hidden;
  .long {
    width: auto;
    display: flex;
    align-items: center;
    // justify-content:space-between;

    li {
      width: 25px;
      height: 25px;
      overflow: hidden;
      img {
        height: 25px;
        width: 25px;
      }
    }
  }
  .user-header {
    width: 20px;
    height: 20px;
    background: #39a9ed;
    margin-right: 25px;
    border-radius: 25px;
    transition: all 0.8;
    img {
      width: 100%;
    }
  }
}

.box {
  width: 70%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: -70%;
}

.user-swipe {
  .van-swipe-item {
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .img {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
      }
    }
    .user-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      line-height: 1.5;
      margin-left: 20px;
      > div {
        text-align: left;
        &:nth-child(2) {
          font-size: 14px;
        }
        &:nth-child(3) {
          font-size: 14px;
        }
      }
    }
  }
}

@keyframes appear {
  0% {
    left: -70%;
  }
  100% {
    left: 0%;
  }
}
@keyframes disappear {
  0% {
    left: 0%;
  }
  100% {
    left: -70%;
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.appear {
  left: 0;
  -webkit-animation-name: appear;
  animation-name: appear;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}
.disappear {
  left: -70%;
  -webkit-animation-name: disappear;
  animation-name: disappear;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}
.scale {
  -webkit-animation-name: scale;
  animation-name: scale;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transform: scale(1.5);
}

li {
  list-style: none;
}

.outside {
  position: relative;

  width: 320px;
  height: 60px;
  overflow: hidden;

  .user {
    width: 320px;
    position: relative;
    .long {
      // width: 640px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.arrow-box {
  display: flex;

  .arrow {
    display: flex;
    align-items: center;
  }
}

video {
  width: 100%;
  // height: ;
  height: 210px;
}

.check_video {
  display: flex;
  color: #ffffff;
  margin-top: -5px;

  .live_video {
    width: 80%;
    background-color: #3e3b39;
    display: flex;
    padding: 2px 0;
    transition: 0.35s;
    font-size: 12px;
    .title {
      border-right: none !important;
    }
    > div {
      width: 80px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-right: 1px solid #ffffff;
    }
  }
  .has_video {
    display: flex;
    width: 20%;
    padding: 2px 0;
    transition: 0.35s;
    background-color: #8e8e8e;
    font-size: 12px;

    .title {
      border-right: none !important;
    }
    > div {
      height: 25px;
      line-height: 25px;
      width: 80px;
      text-align: center;
      border-right: 1px solid #ffffff;
    }
  }
}
.active {
  font-weight: bolder;
  font-size: 14px;
}

.intro{
  padding: 0 25px;
  display: flex;
  .img{
    display:flex;
    width: 100px;
    height: 100px;
    		flex-basis: 35%;
    img{
      width: 100%;
    }
  }
  .text{
    margin-left: 10px;
    width: 70%;
    font-size: 12px;
    .info{
      span{
        margin-right: 10px;
      }
    }
    // display: flex;
    // flex-basis:70%
  }
}


.header_active{
  transform: scale(1.5);
  transition: .35s;
}
</style>
