<template>
  <div>
    <div class="contact-us">
      <!-- <div class="title">联系我们</div> -->
      <div class="user">
        <div
          :class="[currentScale==index?'zoom-index':'']"
          :key="index"
          class="user-info"
          v-for="(item,index) in parterInfo"
        >
          <div @click="bigAvatar(index)" class="avatar">
            <img :src="item.headAddress" alt />
          </div>
          <div class="infos move" v-show="currentScale==index">
            <div class="user-name">{{item.name}}</div>
            <div class="user-work">{{item.jobs}}</div>
            <div class="user-name" v-permission="{action:tokenStatus}">{{item.phone}}</div>
          </div>
        </div>
      </div>
      <!-- <div class="no-token" v-noLimit="{action:tokenStatus}">
        <div class="xb-user-head" :key="index"  v-for="(item,index) in parterInfo">
          <div class="avatar">
            <img :src="item.headAddress" alt />
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      parterInfo: [
        {
          id: 0,
          name: 'kang',
          work: "laoban",
          phone: "123445"
        },
        {
          id: 1,
          name: 'kang',
          work: "laoban",
          phone: "123445"
        },
        {
          id: 2,
          name: 'kang',
          work: "laoban",
          phone: "123445"
        },

      ],
      scale: false,
      currentScale: null
    }
  },
  created () {
  },
  mounted () {
    this.getParterInfo();
  },
  computed: {
    ...mapState(['tokenStatus'])
  },
  methods: {
    //点击头像方法且出现简介信息
    bigAvatar (index) {
      this.currentScale = index
      this.scale = !this.scale
      let dom = document.querySelectorAll('.avatar');
      let infosDom = document.querySelectorAll('.infos');


      dom.forEach((item, i) => {
        // debugger

        if (index == i && this.scale) {
          dom[index].classList.add('scale')
          infosDom[index].classList.add('active')

        } else {
          dom[i].classList.remove('scale')
          infosDom[i].classList.remove('active')

        }
      })



      // console.log('dom', infosDom);
      // if (this.scale) {
      //   dom[index].classList.add('scale')
      //   infosDom[index].classList.add('active')
      // } else {
      //   dom[index].classList.remove('scale')
      //   infosDom[index].classList.remove('active')
      // }

    },
    // 获取员工的信息（头像，职位，电话）
    getParterInfo () {
      this.$request.get(this.$api.parterInfo).then(res => {
        console.log('res000', res);
        this.parterInfo = res.data.content;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  margin-bottom: 10px;
}
@keyframes move {
  0% {
    opacity: 0;
    left: 0;
  }
  50% {
    // opacity: 0.5;
    // top:-13px;
  }
  100% {
    opacity: 1;
    left:68px;
  }
}
.move {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
  // animation-iteration-count: infinite;
  // -webkit-animation-iteration-count: infinite;
  // font-style: inherit;
  // -webkit-animation-timing-function: linear;
  // animation-timing-function: linear;
}
.user {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .user-info {
    width: 50px;
    height: 50px;
    box-sizing: border-box;

    // width: 150px;
    border-radius: 3px;
    // background-color: rgba(92, 99, 110, 0.2);
    // margin-bottom: 25px;
    display: flex;
    justify-content: center;
    position: relative;
    > .avatar {
      transition: 0.8s;
      position: relative;
      z-index: 2;
      // position:absolute;
      // left: 20%;
      // margin-right: -25px;
      width: 50px;
      height: 50px;
      // margin: 0 auto;
      border-radius: 50%;
      background-color: yellow;
      img {
        width: 100%;
      }
    }
    > .infos {
      z-index: -1;
      display: none;
      margin-left: -10px;
      top:-26px;

      // transition: 0.8s;
      // position:absolute;
      // left: 50%;
      width: 150px;
      // margin-left: -25px;
      > div {
        padding-left: 45px;
        text-align: left;
        width: 100%;
        background: #ecf5ff;
        margin-top: 10px;
      }
    }
  }
}

.no-token {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .xb-user-head {
    margin-bottom: 15px;
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: yellow;
    }
  }
}
.hidden {
  display: none;
}
.scale {
  transform: scale(2);
}

.active {
  display: block !important;
  position: absolute !important;
  width: 150px !important;
  background: #fff;
  z-index: 99;
  left: 68px;
}
.zoom-index {
  z-index: 99;
}
</style>
